import { template as template_df40491c4f8243e492504be69fd54299 } from "@ember/template-compiler";
const SidebarSectionMessage = template_df40491c4f8243e492504be69fd54299(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
