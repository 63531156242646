import { template as template_ca7c76d5d6bc44f2abedaa1fe3ce942f } from "@ember/template-compiler";
const FKLabel = template_ca7c76d5d6bc44f2abedaa1fe3ce942f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
