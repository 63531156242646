import { template as template_d726b7491ccd4d75b4e02f0baa18c1ed } from "@ember/template-compiler";
const FKControlMenuContainer = template_d726b7491ccd4d75b4e02f0baa18c1ed(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
